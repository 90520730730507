import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ImageUrl } from "../../../utils/constants";
import { StyledBody } from "../../ChallengeDashboardV2/TeamChallengeInvitePopup/styles";
import './styles';
import { StyledImagePreview } from "./styles";
import { StyledModalV2 } from "../../ChallengeDetailsV2/StartChallengePopUp/styles";
import { closeIcon } from "../../../utils/icons";

class PreviewImageModal extends Component {
  getFileExtension = (filename) => filename.split(".").pop().toLowerCase();

  render() {
    const { showModal, onClose, documentData } = this.props;

    return (
      <StyledModalV2
        show={showModal}
        onHide={onClose}
        width={'1000px'}
      >
        <Modal.Header style={{ background: "#005C87" }}>
       
          <div>
            <span style={{color:"white"}}>Preview</span>
          </div>
          <div onClick={onClose} style={{cursor:"pointer"}}>
            {closeIcon("white")}
          </div>
        </Modal.Header>
        <StyledBody>
          <topBar>
            <StyledImagePreview
              src={`${ImageUrl}/${documentData.document_image}`}
              alt="Document Preview"
            />
          </topBar>
        </StyledBody>
      </StyledModalV2>
    );
  }
}

PreviewImageModal.defaultProps = {
  challenge: { name: "", duration: "", body: "" },
};

PreviewImageModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  documentData: PropTypes.object.isRequired,
  documentData1: PropTypes.object.isRequired,
};

export default PreviewImageModal;
