import styled from "styled-components";

const topBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;  // Ensures the content is vertically centered
  text-align: center; // Centers text and other inline elements
`;

const StyledImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;  // Ensures the image fits properly within the container
`;

const StyledPdfContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // This ensures the PDF stays centered
`;

export { topBar, StyledImagePreview, StyledPdfContainer };
